import React from 'react';

import { token } from '@atlaskit/tokens';
import GridIconCore from '@atlaskit/icon/core/grid';

import { useSSRPlaceholderReplaceIdProp } from '@confluence/loadable';

export const GridIcon = ({ isSelected }: { isSelected: boolean }) => {
	const ssrPlaceholderIdProp = useSSRPlaceholderReplaceIdProp();
	return (
		<GridIconCore
			spacing="spacious"
			color={isSelected ? token('color.icon.selected') : token('color.icon')}
			label=""
			{...ssrPlaceholderIdProp}
		/>
	);
};
